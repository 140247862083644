import { createTheme } from "@mui/material";

declare module "@mui/material" {
  interface Palette {
    blue1hex?: Palette["primary"];
    blue2hex?: Palette["primary"];
    blue3hex?: Palette["primary"];
    blue4hex?: Palette["primary"];
  }
  interface PaletteOptions {
    blue1hex?: PaletteOptions["primary"];
    blue2hex?: PaletteOptions["primary"];
    blue3hex?: PaletteOptions["primary"];
    blue4hex?: PaletteOptions["primary"];
  }
}

export const colours = {
  blue1hex: "#0540F2",
  blue2hex: "#044BD9",
  blue3hex: "#0583F2",
  blue4hex: "#05AFF2",
  selectionBlue: "#05C7F2",

  backgroundBlue: "rgba(5, 173, 240, 0.1)",
  white: "rgb(255, 255, 255)",

  calmRed: "#EB3446",

  charcoal: "#212121",
  charcoalLight: "#434343",
};
export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          transition: "all 0.2s",
          ":hover": {
            transform: "scale(1.1)",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          transition: "all 0.2s",
          ":hover": {
            transform: "scale(1.1)",
          },
        },
      },
    },
  },
  typography: {
    h3: {
      fontSize: "20px",
      fontWeight: 700,
    },
    fontFamily: "'Montserrat', sans-serif",
  },
  palette: {
    primary: {
      main: colours.blue2hex,
    },
    error: {
      main: colours.calmRed,
    },
    blue1hex: {
      main: "#0540F2",
    },
    blue2hex: {
      main: "#044BD9",
    },
    blue3hex: {
      main: "#0583F2",
    },
  },
});
