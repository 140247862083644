import { useAppSelector } from "../app/hooks";
import { activeLoadingEvents } from "../appSlice";
import "./Loader.scss";

function ActiveLoadingComponent() {
    let loadingCount = useAppSelector(activeLoadingEvents);
    if (loadingCount > 0) {
        return(
            <div className={"activeLoadingElement"}>
            <div className={"loadingBar"}></div>
        </div>
        )
    } else {
        return <></>;
    }
}

export default ActiveLoadingComponent;