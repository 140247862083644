import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import Toast from "../components/Toast";
import { logout, sessionExpiry, userLoggedIn } from "../pages/login/loginSlice";
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./Page.scss";

function Page(props: any) {
  let dispatch = useAppDispatch();
  let userSignedIn = useAppSelector(userLoggedIn);
  let expiryTime = useAppSelector(sessionExpiry);
  let [logoutWarning, setLogoutWarning] = useState("");
  let logoutTimer;
  if (userSignedIn && expiryTime !== null) {
    // We need to warn the user when their session is going to expire
    // We also need to trigger logout when the session does expire
    if (Date.now() > expiryTime) {
      // We should already be logged out
      dispatch(logout());
    } else {
      if (!logoutTimer) {
        logoutTimer = setTimeout(() => {
          // When this time comes log out
          dispatch(logout());
        }, expiryTime - Date.now());
      }
      let fiveMinsBeforeExpiry = expiryTime - 300000;
      if (Date.now() > fiveMinsBeforeExpiry && logoutWarning === "") {
        setLogoutWarning("Your session is about to expire due to inactivity");
      } else {
        setTimeout(() => {
          setLogoutWarning("Your session is about to expire due to inactivity");
        }, fiveMinsBeforeExpiry - Date.now());
      }
    }
  }
  return (
    <div>
      <Header />
      <main>{props.children}</main>
      {logoutWarning.length ? <Toast>{logoutWarning}</Toast> : <></>}
      <Footer />
    </div>
  );
}

export default Page;
