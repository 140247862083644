import { useAppSelector } from "../app/hooks";
import { fsLoadingEvents } from "../appSlice";
import "./Loader.scss";

function FullScreenLoader() {
    let loadingCount = useAppSelector(fsLoadingEvents);
    if (loadingCount > 0) {
        return(
            <div className={"fsLoader"}>
            <div className={"loadingBar"}></div>
            <div className={"loadingBlur"}></div>
        </div>
        )
    } else {
        return <></>;
    }
}

export default FullScreenLoader;