import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { decreaseActiveLoading, increaseActiveLoading } from "../../appSlice";
import { Config } from "../../config";
import {
  activeProject,
  inflateProjects,
  setActiveProject,
} from "../create-project/projectSlice";
import { currentUserToken } from "../login/loginSlice";
import "./FileBrowser.scss";
import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import { Delete, Download, Inbox, MoreVert } from "@mui/icons-material";

const FileContextMenu: React.FC<{
  isDisplayed: boolean;
  xPos: number;
  yPos: number;
  onClose: () => void;
}> = ({ isDisplayed, xPos, yPos, onClose }) => {
  return (
    <Menu
      open={isDisplayed}
      anchorReference="anchorPosition"
      anchorPosition={{ top: yPos, left: xPos }}
      onClose={onClose}
    >
      <MenuList>
        <MenuItem>
          <ListItemIcon>
            <Download />
          </ListItemIcon>
          <ListItemText>Download Item</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ color: "error" }}
            primary={"Delete Item"}
          />
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const FileIcon: React.FC<{
  setContextMenu: (contextMenu: {
    isDisplayed: boolean;
    position: { x: number; y: number };
  }) => void;
  children: { filename: string };
}> = ({ setContextMenu, children: { filename } }) => {
  return (
    <Paper sx={{ cursor: "pointer", padding: 2, height: 250 }}>
      <Grid container>
        <Grid item xs={12} sx={{ height: 200 }}>
          <Typography>Image goes here</Typography>
        </Grid>
        <Grid item container xs={12} sx={{ height: 50 }}>
          <Grid item xs={10} zeroMinWidth>
            <Box>
              <Typography noWrap variant={"h4"}>
                {filename}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} textAlign={"right"}>
            <IconButton
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                setContextMenu({
                  isDisplayed: true,
                  position: { x: ev.clientX, y: ev.clientY },
                });
              }}
            >
              <MoreVert />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const FileList: React.FC<{
  files: any;
  setContextMenu: (contextMenu: {
    isDisplayed: boolean;
    position: { x: number; y: number };
  }) => void;
}> = ({ files, setContextMenu }) => {
  let userToken = useAppSelector(currentUserToken);
  let dispatch = useAppDispatch();
  let projectGuid = useAppSelector(activeProject);
  let fileList = files.map((fileItem: any) => {
    return (
      <Grid
        item
        xs={12}
        md={3}
        sx={{ margin: 1 }}
        key={fileItem.filename}
        onClick={() => {
          // Initiate download
          dispatch(increaseActiveLoading());
          fetch(
            Config.apiEndpoint +
              "/project/" +
              projectGuid?.guid +
              "/file?filename=" +
              fileItem.filename,
            {
              method: "GET",
              mode: "cors",
              headers: { Authorization: "Bearer " + userToken },
            }
          )
            .then((data) => {
              return data.json();
            })
            .then((data) => {
              fetch(data.presignedUrl, {
                method: "GET",
                mode: "cors",
              })
                .then((response) => response.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", fileItem.filename);
                  document.body.appendChild(link);
                  link.click();
                  link.parentNode!.removeChild(link);
                  dispatch(decreaseActiveLoading());
                });
            });
        }}
      >
        <FileIcon setContextMenu={setContextMenu}>{fileItem}</FileIcon>
      </Grid>
    );
  });
  return <Grid container>{fileList}</Grid>;
};

function ProjectUsage(props: { usage?: number; limit?: number }) {
  let humanUsage = props.usage ?? 0;
  let humanUsageMeasurementIndex = 0;
  let humanTotal = props.limit ?? 0;
  let humanTotalMeasurementIndex = 0;
  let labels: { usage: string; total: string } = {
    usage: "",
    total: "",
  };
  let humanMeasurements = ["B", "KB", "MB", "GB", "TB", "PB", "EB"]; // We aren't going to have more than an exabyte of data now
  while (humanUsage > 1000) {
    humanUsage = humanUsage / 1024;
    humanUsageMeasurementIndex++;
  }
  labels.usage =
    +humanUsage.toFixed(2) + humanMeasurements[humanUsageMeasurementIndex];
  while (humanTotal > 1000) {
    humanTotal = humanTotal / 1024;
    humanTotalMeasurementIndex++;
  }
  labels.total =
    +humanTotal.toFixed(2) + humanMeasurements[humanTotalMeasurementIndex];
  let usagePercent =
    props.usage && props.limit
      ? Math.round((props.usage / props.limit) * 100)
      : 0;
  return (
    <div className="projectUsageOuter">
      <div className="totalUsage">
        <div
          className="currentUsage"
          style={{ width: usagePercent + "%" }}
        ></div>
        <div className="usageText">
          {labels.usage} / {labels.total}
        </div>
      </div>
    </div>
  );
}

const FileBrowser = () => {
  const userToken = useAppSelector(currentUserToken);
  const dispatch = useAppDispatch();
  const [fetchingProjects, setFetchingProjects] = useState(false);
  const projectGuid = useAppSelector(activeProject);
  const [override, setOverride] = useState(<></>);
  const [contextMenu, setContextMenu] = useState<{
    isDisplayed: boolean;
    position: { x: number; y: number };
  }>({ isDisplayed: false, position: { x: 0, y: 0 } });
  const [files, setFiles] = useState([]);
  const [lastFileRefreshTime, setLastFileRefreshTime] = useState(0);
  const [projectUsage, setProjectUsage] = useState(0);
  const [projectLimit, setProjectLimit] = useState(10737418240); // Default to 10GB in bytes
  if (projectGuid === null) {
    // Find out if user can access any projects - otherwise they cannot browse files so we will redirect them to create a project
    if (!fetchingProjects) {
      // Do it
      setFetchingProjects(true);
      dispatch(increaseActiveLoading());
      fetch(Config.apiEndpoint + "/project/list", {
        method: "GET",
        headers: { Authorization: "Bearer " + userToken },
        mode: "cors",
      })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          if (data.projects.length > 0) {
            dispatch(decreaseActiveLoading());
            dispatch(inflateProjects(data.projects));
            dispatch(setActiveProject(data.projects[0]));
          } else {
            dispatch(decreaseActiveLoading());
            setOverride(<Navigate to="/create-project" />);
          }
        });
    }
  }

  const uploadFile = (file: any) => {
    // Get presigned upload URL
    // Upload to given URL
    fetch(Config.apiEndpoint + "/project/" + projectGuid?.guid + "/upload", {
      method: "POST",
      mode: "cors",
      headers: { Authorization: "Bearer " + userToken },
      body: JSON.stringify({
        location: file.name,
      }),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        let uploadURL = data.presignedUrl;
        fetch(uploadURL, {
          method: "PUT",
          mode: "cors",
          body: file,
        }).then((data) => {
          console.log(data);
          // TODO: Handle completed upload
        });
      });
  };

  const refreshFiles = () => {
    if (projectGuid?.guid) {
      dispatch(increaseActiveLoading());
      // Refresh usage while we are at it
      fetch(Config.apiEndpoint + "/project/" + projectGuid.guid + "/usage", {
        method: "GET",
        mode: "cors",
        headers: { Authorization: "Bearer " + userToken },
      })
        .then((data) => {
          return data.json();
        })
        .then((usageData) => {
          // Now we have our usage - get the files
          setProjectUsage(usageData.size);
          setProjectLimit(usageData.limit);
          fetch(
            Config.apiEndpoint + "/project/" + projectGuid?.guid + "/list",
            {
              method: "GET",
              mode: "cors",
              headers: { Authorization: "Bearer " + userToken },
            }
          )
            .then((data) => {
              return data.json();
            })
            .then((data) => {
              if (data && data.contents) {
                setFiles(
                  data.contents.map((file: any) => {
                    return {
                      filename: file.Key.substring(
                        (projectGuid?.guid + "/").length
                      ),
                    };
                  })
                );
                setLastFileRefreshTime(Date.now());
              }
              dispatch(decreaseActiveLoading());
            });
        });
    }
  };

  const [dragActive, setDragActive] = useState(false);

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    setDragActive(false);
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      // We have files to upload
      [...e.dataTransfer.files].forEach((file: any) => {
        uploadFile(file);
      });
    }
  };

  if (
    (!files || files.length <= 0) &&
    lastFileRefreshTime < Date.now() - 1000
  ) {
    refreshFiles();
  }
  return (
    <div className={"fileBrowser"} onDragEnter={handleDrag}>
      {override}
      {contextMenu.isDisplayed && (
        <FileContextMenu
          isDisplayed={contextMenu.isDisplayed}
          xPos={contextMenu.position.x}
          yPos={contextMenu.position.y}
          onClose={() => {
            setContextMenu({ isDisplayed: false, position: { x: 0, y: 0 } });
          }}
        />
      )}
      <FileList
        files={files}
        setContextMenu={(cm) => {
          setContextMenu(cm);
        }}
      />
      <ProjectUsage usage={projectUsage} limit={projectLimit} />
      {dragActive && (
        <div
          id="draggedArea"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <div className={"dragBackground"}></div>
          <div className={"dragBorder"}>
            <div>
              <Inbox style={{ fontSize: 140 }} />
              <p>Drop Items Here to Upload to the Current Folder</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileBrowser;
