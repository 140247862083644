import "./About.scss";
import { useEffect, useState } from "react";

function About() {
    useEffect(() => {
        const typewritter = setInterval(() => {
            if (competitorReset > 0) {
                setCompetitorReset(competitorReset - 1);
            } else {
                if (competitorDir === 'l') {
                    if (competitor.length > 0) {
                        setCompetitor(competitor.substring(0, competitor.length - 1));
                    } else {
                        setCompetitorDir('r');
                        if ((competitorIndex + 1) >= competitors.length) {
                            setCompetitorIndex(0);
                        } else {
                            setCompetitorIndex(competitorIndex + 1);
                        }
                    }
                } else {
                    if (competitor.length < competitors[competitorIndex].length) {
                        setCompetitor(competitors[competitorIndex].substring(0, competitor.length + 1));
                    } else {
                        setCompetitorReset(25);
                        setCompetitorDir('l');
                    }
                }
            }
        }, 50);
        return () => clearInterval(typewritter);
    });
    let [competitor, setCompetitor] = useState("Google Drive");
    let [competitorIndex, setCompetitorIndex] = useState(0);
    let [competitorDir, setCompetitorDir]= useState('l');
    let [competitorReset, setCompetitorReset] = useState(0);
    let competitors = ["Google Drive", "One Drive", "DropBox"];
    return(<div className={"contentPage"}>
        <h1>Team Drive</h1>
        <h2>Like {competitor} - <em>but cheaper!</em></h2>
        <p>Let's just start with the plain obvious - if you are a large company, well established, or have plenty of cash to burn, this service is not for you.</p>
        {/* Link to our own website - we know it isn't malicious, take referrals */}
        {/* eslint-disable-next-line */}
        <p>Still reading? Then you must be a small company, perhaps even a start up! Hey! Go you! Starting a business is a tough gig - we know all about it! Here at <a href={"https://prudentpixel.com"} target={"_blank"} rel={"noopener"}>Prudent Pixel</a> we've been through it - and truth be told, after a tough couple of years mid COVID-19 pandemic, we changed our approach. That's okay in business. You see we realised that when you're just getting started, every penny matters. That's why we took a bunch of internal tools and re-wrote them to make them available for other startups. Enter Team Drive!</p>
        <h3>What does it solve?</h3>
        <p>If you start a startup with 10 people, you will without any doubt want to share files. Hey it's {new Date().getFullYear()} after all, if you aren't supporting at the very least a hybrid work model what are you even doing. So sure, you can go out and buy a proper cloud sharing suite - but at £4-5 per month per person, we are already talking about £40-50 per month, or £480-600 per year. And is each person in your business really going to fill up 30GB? That's where Team Drive comes in - once your business is kicking goals, move to a complete suite - in the meantime, save yourself some cash - use a cheap email host from the same place you bought your domain from, and collaborate with Team Drive. Pay for storage shared across users, from just 10GB per team with no limit on users. Plus - if you aren't ready for business emails, you can invite colleagues and collaborators with their person address to your team. It's Google Drive - discounted.</p>
        <h3>Okay - but I aim to conquer the world - this won't work forever right?</h3>
        <p>Ya know... Unlike a lot of people, we don't expect you to stay forever. There's a few <em>"competitors"</em> mentioned above - and they are great! We fully expect you will move to them. We use Google Drive internally - but if we paid for enough storage my accountant would not be happy! This service is designed to give you a boost at the start. Let us know when you're ready and we'll help you migrate away 😊</p>
    </div>);
}

export default About;