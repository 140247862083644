import PriceCard from "../../components/PriceCard";
import { Box } from "@mui/material";

function Pricing() {
  return (
    <div className={"contentPage"}>
      <h1>Pricing</h1>
      <h2>
        We can't tell you how much each 100th of a £ matters - because that's a
        major supermarkets slogan and we can't afford to get sued... But it
        does!
      </h2>
      <p>
        We keep pricing simple so you can focus on what matters the most. You
        can scale up anytime you need, pick something in between, or chat with
        us for any other options!
      </p>
      <Box sx={{ display: "flex" }}>
        <PriceCard title={"Basic"} price={10}>
          <p>Recommended for teams of 1 - 10 people</p>
          <p>10 GB Storage</p>
          <p>100 GB Transfers per month</p>
        </PriceCard>
        <PriceCard title={"Basic - but bigger"} price={50}>
          <p>
            Recommended for larger teams or those with larger storage (e.g.
            Videos)
          </p>
          <p>100 GB Storage</p>
          <p>1 TB Transfers per month</p>
        </PriceCard>
        <PriceCard title={"More advanced"} buyLabel={"Speak to an Expert"}>
          <p>You know you want more</p>
          <p>Chat with a specialist to get the best price</p>
        </PriceCard>
      </Box>
    </div>
  );
}

export default Pricing;
