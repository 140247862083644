import "./Footer.scss";
import logo from "../assets/logo_white.png";
import { Link } from "react-router-dom";
import { Config } from "../config";
import { Facebook, LinkedIn } from "@mui/icons-material";
export const Footer: React.FC = () => {
  return (
    <footer>
      <div className={"footerColumns"}>
        <div>
          <picture className={"footerLogo"}>
            <img src={logo} alt={"Team Drive UK"} />
          </picture>
        </div>
        <ul>
          <li>
            <strong>About:</strong>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          {Config.acceptingSignup && (
            <li>
              <Link to="/pricing">Pricing</Link>
            </li>
          )}
        </ul>
        <ul>
          <li>
            <strong>Help:</strong>
          </li>
          <li>
            <Link to="/support">Get Support</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
        </ul>
        <ul>
          <li>
            <strong>Social:</strong>
          </li>
          <li>
            <a
              href="https://www.facebook.com/teamdrive.uk"
              target={"_blank"}
              rel={"noreferrer"}
              className={"socialIcon"}
            >
              <Facebook />
            </a>
            <a
              href="https://www.linkedin.com/company/prudent-pixel-ltd"
              target={"_blank"}
              rel={"noreferrer"}
              className={"socialIcon"}
            >
              <LinkedIn />
            </a>
          </li>
        </ul>
      </div>
      <div className={"baseline"}>
        {/* Link to our own website - we know it isn't malicious, take referrals */}
        {/* eslint-disable-next-line */}A{" "}
        <a href={"https://prudentpixel.com"} target={"_blank"} rel={"noopener"}>
          Prudent Pixel
        </a>{" "}
        Product - &copy; {new Date().getFullYear()}
      </div>
    </footer>
  );
};
