import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface LoginState {
  user: string | null;
  token: string | null;
  betaOptIn: boolean | undefined;
  tokenExpiry: number | null;
  isSigningUp: boolean;
}

const initialState: LoginState = {
  user: null,
  token: null,
  betaOptIn: false,
  tokenExpiry: null,
  isSigningUp: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    login: (state, payload) => {
      state.user = payload.payload.user;
      state.token = payload.payload.token;
      state.betaOptIn = payload.payload.betaOptIn;
      state.tokenExpiry = payload.payload.expiry * 1000;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
    },
    signup: (state) => {
      state.isSigningUp = true;
    },
    cancelSignUp: (state) => {
      state.isSigningUp = false;
    },
  },
});

export const { login, logout, signup, cancelSignUp } = loginSlice.actions;

export const userLoggedIn = (state: RootState) =>
  state.login.token !== null &&
  state.login.tokenExpiry !== null &&
  state.login.tokenExpiry >= Date.now();
export const currentUserToken = (state: RootState) => state.login.token;
export const currentUser = (state: RootState) => {
  return { user: state.login.user, betaOptIn: state.login.betaOptIn };
};
export const sessionExpiry = (state: RootState) => state.login.tokenExpiry;
export const userSigningUp = (state: RootState) => state.login.isSigningUp;

export default loginSlice.reducer;
