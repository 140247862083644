import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ProjectObject {
    guid: string;
    name: string;
    owner: string;
    status: number;
}

export interface ProjectState {
    projectsAvailable: ProjectObject[];
    activeProject: ProjectObject | null;
    ownsCurrentProject: boolean;
    currentProjectRequiresPayment: boolean;
}

const initialState: ProjectState = {
    projectsAvailable: [],
    activeProject: null,
    ownsCurrentProject: false,
    currentProjectRequiresPayment: true
};

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        inflateProjects: (state, payload) => {
            state.projectsAvailable = payload.payload;
        },
        setActiveProject: (state, payload) => {
            state.activeProject = payload.payload;
        }
    }
});

export const { inflateProjects, setActiveProject } = projectSlice.actions;

export const activeProject = (state: RootState) => state.project.activeProject;
export const projectsAvailable = (state: RootState) => state.project.projectsAvailable;

export default projectSlice.reducer;