import './App.scss';
import Login from './pages/login/Login';
import Help from './pages/help/Help';
import Contact from './pages/contact/Contact';
import { useAppSelector } from './app/hooks';
import { userLoggedIn } from './pages/login/loginSlice';
import Page from './templates/Page';
import { Route, Routes } from 'react-router-dom';
import About from './pages/about/About';
import Pricing from './pages/pricing/Pricing';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import FileBrowser from './pages/fileBrowser/FileBrowser';
import CreateProject from './pages/create-project/CreateProject';
import { Settings } from './pages/settings/Settings';

function App() {
  const loggedIn = useAppSelector(userLoggedIn);
  let homePage = <Page><Login /></Page>;
  if (loggedIn) {
    homePage = <Page><FileBrowser /></Page>
  }
  return (
    <Routes>
      <Route path="/" element={homePage} />
      <Route path="/settings" element={<Page><Settings /></Page>} />
      <Route path="/about" element={<Page><About /></Page>} />
      <Route path="/pricing" element={<Page><Pricing /></Page>} />
      <Route path="/support" element={<Page><Help /></Page>} />
      <Route path="/contact" element={<Page><Contact /></Page>} />
      <Route path="/privacy-policy" element={<Page><PrivacyPolicy /></Page>} />
      <Route path="/create-project" element={<Page><CreateProject /></Page>} />
    </Routes>
  );
}

export default App;
