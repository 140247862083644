import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./app/store";

export interface AppState {
    fullScreenLoadingEvents: number;
    activeLoadingEvents: number;
}

const initialState: AppState = {
    fullScreenLoadingEvents: 0,
    activeLoadingEvents: 0,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        increaseFullScreenLoading: (state) => {
            state.fullScreenLoadingEvents++;
        },
        decreaseFullScreenLoading: (state) => {
            state.fullScreenLoadingEvents--;
        },
        increaseActiveLoading: (state) => {
            state.activeLoadingEvents++;
        },
        decreaseActiveLoading: (state) => {
            state.activeLoadingEvents--;
        }
    }
});

export const { increaseFullScreenLoading, decreaseFullScreenLoading, increaseActiveLoading, decreaseActiveLoading } = appSlice.actions;

export const fsLoadingEvents = (state: RootState) => state.app.fullScreenLoadingEvents;
export const activeLoadingEvents = (state: RootState) => state.app.activeLoadingEvents;

export default appSlice.reducer;