import { Box, Button, Typography, useTheme } from "@mui/material";

function PriceCard(props: any) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        flex: "calc(33% - 40px) 0 0",
        margin: "0 10px 30px",
        boxShadow: "1px 1px 3px black",
        borderRadius: 4,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          padding: 3,
          background: `linear-gradient(${theme.palette.blue1hex?.main}, ${theme.palette.blue3hex?.main})`,
        }}
      >
        <Typography variant="h3" sx={{ color: theme.palette.common.white }}>
          {props.title}
        </Typography>
      </Box>
      <Box sx={{ padding: 3, flexGrow: 1 }}>{props.children}</Box>
      <Box sx={{ padding: 3 }}>
        <Button variant="contained" fullWidth>
          {props.buyLabel || "Get Started!"}
        </Button>
      </Box>
    </Box>
  );
}

export default PriceCard;
