import logo from "../assets/logo_transparent.png";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { logout, userLoggedIn } from "../pages/login/loginSlice";
import {
  activeProject,
  projectsAvailable,
} from "../pages/create-project/projectSlice";
import { useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import {
  Assignment,
  Logout,
  ManageAccounts,
  Settings,
  StarHalf,
} from "@mui/icons-material";

export const Header = () => {
  const dispatch = useAppDispatch();
  let projectName = useAppSelector(activeProject)?.name;
  let projectsList = (useAppSelector(projectsAvailable) || []).map(
    (project) => {
      if (project.name === projectName) {
        // Don't return the active project
        return null;
      }
      return <span>{project.name}</span>;
    }
  );
  let [settingsMenu, setSettingsMenu] = useState({
    isDisplayed: false,
    position: { x: 0, y: 0 },
  });

  const SettingsContextMenu: React.FC<{
    isDisplayed: boolean;
    xPos: number;
    yPos: number;
    onClose: () => void;
  }> = ({ isDisplayed, xPos, yPos, onClose }) => {
    return (
      <Menu
        open={isDisplayed}
        anchorReference="anchorPosition"
        anchorPosition={{ top: yPos, left: xPos }}
        onClose={onClose}
      >
        <MenuList>
          <MenuItem>
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText>Project Settings</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <ManageAccounts />
            </ListItemIcon>
            <ListItemText>Account Settings</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <StarHalf />
            </ListItemIcon>
            <ListItemText>Beta Settings</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  return (
    <Grid container>
      <Grid item xs={11} md={4} sx={{ textAlign: "left" }}>
        <Box sx={{ maxWidth: "300px", paddingX: 4 }}>
          <Link to="/">
            <img src={logo} alt={"Team Drive UK"} style={{ width: "100%" }} />
          </Link>
        </Box>
      </Grid>
      <Grid item xs={0} md={4} sx={{ textAlign: "center" }}>
        <Typography variant="h3" sx={{ lineHeight: "50px" }}>
          {projectName}
        </Typography>
        {projectsList}
      </Grid>
      <Grid item xs={1} md={4} sx={{ textAlign: "right" }} spacing={2}>
        {useAppSelector(userLoggedIn) && (
          <Box padding={1}>
            <SettingsContextMenu
              isDisplayed={settingsMenu.isDisplayed}
              xPos={settingsMenu.position.x}
              yPos={settingsMenu.position.y}
              onClose={() => {
                setSettingsMenu({
                  isDisplayed: false,
                  position: { x: 0, y: 0 },
                });
              }}
            />
            <IconButton
              onClick={(e) =>
                setSettingsMenu({
                  isDisplayed: true,
                  position: { x: e.clientX, y: e.clientY },
                })
              }
            >
              <Settings />
            </IconButton>
            <Button
              variant="contained"
              color="error"
              sx={{ marginRight: "20px" }}
              onClick={() => dispatch(logout())}
            >
              LOG OUT <Logout />
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
