import { useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { decreaseFullScreenLoading, increaseFullScreenLoading } from "../../appSlice";
import { Config } from "../../config";
import { currentUserToken } from "../login/loginSlice";
import "./CreateProject.scss";

function CreateProject() {
    let dispatch = useAppDispatch();
    let userToken = useAppSelector(currentUserToken);
    const createProject = () => {
        // TODO: Validate - especially RE invoice v c/c
        dispatch(increaseFullScreenLoading());
        // Here we go let's make this project
        fetch(Config.apiEndpoint + "/project/create", {
            method: "POST",
            mode: "cors",
            headers: {Authorization: "Bearer " + userToken},
            body: JSON.stringify({
                name: projectName
            })
        }).then((data) => {
            return data.json()
        }).then((data) => {
            // Project created
            console.log(data);
            dispatch(decreaseFullScreenLoading());
        });
    }

    let [projectName, setProjectName] = useState("");
    let [plan, setPlan] = useState("10G/100G");
    let [paymentOption, setPaymentOption] = useState("credit_card");
    let [creditCardNumber, setCreditCardNumber] = useState("");
    let [creditCardExpiry, setCreditCardExpiry] = useState("");
    let [creditCardCCV, setCreditCardCCV] = useState("");
    let [invoiceEmailAddress, setInvoiceEmailAddress] = useState("");
    let [agreeToTAndCs, setAgreeToTAndCs] = useState(false);
    return(<div id="createProjectPage" className={"contentPage"}>
        <h1>CREATE NEW PROJECT</h1>
        <label>Project Name:
            <input type="text" value={projectName} onChange={(e) => setProjectName(e.target.value)}  />
        </label>
        <label>
            Plan type:
            <small>NB: You can upgrade this at any time - we recommend you start with the smallest and scale up as you need</small>
            <select value={plan} onChange={(e) => setPlan(e.target.value)}>
                <option value="10G/100G">Basic - 10GB Storage</option>
                <option value="100G/1T">Basic - but bigger - 100GB Storage</option>
            </select>
            <small>Need something else? <Link to="/contact">Contact Us</Link> and we will sort out the perfect solution!</small>
        </label>
        <label>
            Payment Option:
            <select value={paymentOption} onChange={(e) => setPaymentOption(e.target.value)}>
                <option value="credit_card">Credit Card (Recommended)</option>
                <option value="invoice">Invoice</option>
            </select>
            {paymentOption === "credit_card" ? <></> : <small>NB: If you select to pay via invoice - you must make payment and it must be received before your service will be activated. Payments can take a few business days to be delivered. We recommend paying via Credit Card for instant access</small>}
        </label>
        {paymentOption === "credit_card" ?
        <><label>Credit Card Number:
            <input type="text" value={creditCardNumber} onChange={(e) => setCreditCardNumber(e.target.value)} />
        </label>
        <label>Credit Card Expiry:
            <input type="text" value={creditCardExpiry} onChange={(e) => setCreditCardExpiry(e.target.value)} />
        </label>
        <label>Credit Card CCV:
            <input type="text" value={creditCardCCV} onChange={(e) => setCreditCardCCV(e.target.value)} />
        </label></>
        : <>
        <label>Email Address to Invoice: 
            <input type="email" value={invoiceEmailAddress} onChange={(e) => setInvoiceEmailAddress(e.target.value)} />
        </label>
        </>
        }
        <label>
            <input type={"checkbox"} checked={agreeToTAndCs} onChange={(e) => setAgreeToTAndCs(e.target.checked)} /> I have read and agree to the Terms and Conditions of Sale and Privacy Policy
        </label>
        <strong>Monthly Cost <span>£{plan === "10G/100G" ? "12": "120"}GBP</span> per month</strong>
        <span>Price includes £{plan === "10G/100G" ? "2": "20"} VAT (UK Rate of 20%)</span>
        <button className={"genericBtn"} onClick={createProject}>Subscribe</button>
    </div>);
}

export default CreateProject;