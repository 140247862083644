import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { login, signup, cancelSignUp, userSigningUp } from "./loginSlice";
import "./Login.scss";
import { Config } from "../../config";
import { Buffer } from "buffer";
import { ReactElement, useState } from "react";
import {
  decreaseFullScreenLoading,
  increaseFullScreenLoading,
} from "../../appSlice";

const Login: React.FC = () => {
  const [userDetails, setUserDetails] = useState<{
    email: string;
    name: string;
    password: string;
    confirmPassword: string;
  }>({
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
  });
  const dispatch = useAppDispatch();
  const signupInProgress = useAppSelector(userSigningUp);
  const doLogin = async () => {
    dispatch(increaseFullScreenLoading());
    setLoginError(<></>);
    if (validate()) {
      await fetch(Config.apiEndpoint + "/auth/login/", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          email: userDetails.email,
          password: userDetails.password,
        }),
      })
        .then((response) => {
          if (response.status === 401) {
            // Wrong password
            throw new Error("LOGIN_FAILED");
          } else if (response.status === 200) {
            return response.text();
          } else {
            throw response;
          }
        })
        .then((token) => {
          const tokenData = JSON.parse(
            Buffer.from(token.split(".")[1], "base64").toString()
          );
          dispatch(
            login({
              user: tokenData.user,
              token: token,
              betaOptIn: tokenData.betaOptIn,
              expiry: tokenData.exp,
            })
          );
        })
        .catch((error) => {
          if (error.message === "LOGIN_FAILED") {
            // Display error
            setUserDetails((x) => {
              return { ...x, password: "" };
            });
            setLoginError(
              <span className={"validation"}>
                Login error. Please double check email and password.
              </span>
            );
          }
        });
    }
    dispatch(decreaseFullScreenLoading());
  };
  const doSignup = async () => {
    dispatch(increaseFullScreenLoading());
    if (validate()) {
      await fetch(Config.apiEndpoint + "/auth/signup/", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          name: userDetails.name,
          email: userDetails.email,
          password: userDetails.password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          dispatch(login(""));
        });
    }
    dispatch(decreaseFullScreenLoading());
  };
  const [validation, setValidation] = useState<{
    name: ReactElement;
    email: ReactElement;
    password: ReactElement;
    confirm: ReactElement;
  }>({
    name: <span></span>,
    email: <span></span>,
    password: <span></span>,
    confirm: <span></span>,
  });
  let [loginError, setLoginError] = useState(<></>);
  const validate = () => {
    let passing = true;
    if (signupInProgress && userDetails.name.length < 3) {
      passing = false;
      setValidation((x) => {
        return {
          ...x,
          name: (
            <span className="validation">
              Name must be at least 3 characters
            </span>
          ),
        };
      });
    } else {
      setValidation((x) => {
        return { ...x, name: <span></span> };
      });
    }
    if (!userDetails.email || !userDetails.email.match(/.+@.+\..+/)) {
      passing = false;
      setValidation((x) => {
        return {
          ...x,
          email: <span className="validation">Please enter a valid email</span>,
        };
      });
    } else {
      setValidation((x) => {
        return { ...x, email: <span></span> };
      });
    }
    if (
      signupInProgress &&
      (!userDetails.password || userDetails.password.length < 8)
    ) {
      passing = false;
      setValidation((x) => {
        return {
          ...x,
          password: (
            <span className="validation">
              Password must be at least 8 characters
            </span>
          ),
        };
      });
    } else if (
      !signupInProgress &&
      (!userDetails.password || userDetails.password.length < 1)
    ) {
      setValidation((x) => {
        return {
          ...x,
          password: (
            <span className="validation">Please enter your password</span>
          ),
        };
      });
    } else {
      setValidation((x) => {
        return { ...x, password: <span></span> };
      });
    }
    if (
      signupInProgress &&
      (!userDetails.confirmPassword ||
        userDetails.confirmPassword !== userDetails.password)
    ) {
      passing = false;
      setValidation((x) => {
        return {
          ...x,
          confirm: <span className="validation">Passwords must match</span>,
        };
      });
    } else {
      setValidation((x) => {
        return { ...x, confirm: <span></span> };
      });
    }
    return passing;
  };
  let backgroundBlobClass = "backgroundBlob";
  let leftDiv;
  let rightDiv;
  let loginForm;
  if (signupInProgress) {
    backgroundBlobClass += " signingUp";
    loginForm = (
      <div className={"loginForm"}>
        <div>
          <input
            type={"text"}
            placeholder={"Name"}
            value={userDetails.name}
            onChange={(e) =>
              setUserDetails((x) => {
                return { ...x, name: e.target.value };
              })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                doSignup();
              }
            }}
          />
          {validation.name}
          <input
            type={"email"}
            placeholder={"Email"}
            value={userDetails.email}
            onChange={(e) =>
              setUserDetails((x) => {
                return { ...x, email: e.target.value };
              })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                doSignup();
              }
            }}
          />
          {validation.email}
          <input
            type={"password"}
            placeholder={"Password"}
            value={userDetails.password}
            onChange={(e) =>
              setUserDetails((x) => {
                return { ...x, password: e.target.value };
              })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                doSignup();
              }
            }}
          />
          {validation.password}
          <input
            type={"password"}
            placeholder={"Confirm Password"}
            value={userDetails.confirmPassword}
            onChange={(e) =>
              setUserDetails((x) => {
                return { ...x, confirmPassword: e.target.value };
              })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                doSignup();
              }
            }}
          />
          {validation.confirm}
          <button onClick={doSignup} className={"signupBtn"}>
            Sign Up
          </button>
          <button onClick={() => dispatch(cancelSignUp())}>
            Done this before? Login
          </button>
        </div>
      </div>
    );
    rightDiv = (
      <div className="motivation">
        <p>Welcome - we already know you're gonna do great 😉</p>
      </div>
    );
  } else {
    loginForm = (
      <div className={"loginForm"}>
        <div>
          <input
            type={"email"}
            placeholder={"Email Address"}
            value={userDetails.email}
            onChange={(e) =>
              setUserDetails((x) => {
                return { ...x, email: e.target.value };
              })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                doLogin();
              }
            }}
          />
          {validation.email}
          <input
            type={"password"}
            placeholder={"Password"}
            value={userDetails.password}
            onChange={(e) =>
              setUserDetails((x) => {
                return { ...x, password: e.target.value };
              })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                doLogin();
              }
            }}
          />
          {validation.password}
          {loginError}
          <button onClick={doLogin} className={"loginBtn"}>
            Login
          </button>
          {Config.acceptingSignup && (
            <button onClick={() => dispatch(signup())}>
              New around here? Sign up!
            </button>
          )}
        </div>
      </div>
    );
    leftDiv = (
      <div className={"motivation"}>
        <p>Your team can't wait to see what you've been working on 😏</p>
      </div>
    );
  }
  return (
    <div className="loginPage">
      <div className={backgroundBlobClass}></div>
      {leftDiv}
      {loginForm}
      {rightDiv}
    </div>
  );
};

export default Login;
