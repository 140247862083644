import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./Settings.scss";
import { Button, Input, Switch, Tab, Tabs } from "@mui/material";
import { Config } from "../../config";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { currentUser, currentUserToken } from "../login/loginSlice";
import { decreaseActiveLoading, increaseActiveLoading } from "../../appSlice";

export const Settings = () => {
  const userToken = useAppSelector(currentUserToken);
  const currentLoggedInUser = useAppSelector(currentUser);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(
    searchParams.get("tab") || "account"
  );

  return (
    <div className={"contentPage"}>
      <h2>Settings</h2>
      <div className="settingsWindow">
        <Tabs
          orientation="vertical"
          value={selectedTab}
          onChange={(event, newTab) => {
            setSelectedTab(newTab);
          }}
        >
          <Tab
            value="project"
            label="Project Settings"
            onClick={() =>
              createBrowserHistory().replace("/settings?tab=project")
            }
          />
          <Tab
            value="account"
            label="Account Settings"
            onClick={() =>
              createBrowserHistory().replace("/settings?tab=account")
            }
          />
          <Tab
            value="beta"
            label="Beta Settings"
            onClick={() => createBrowserHistory().replace("/settings?tab=beta")}
          />
        </Tabs>
        <div className="tabWindow">
          {selectedTab === "project" && (
            <>
              <h3>Project</h3>
              <table>
                <tr>
                  <th>Project Name:</th>
                  <td>
                    <Input value={"TEST"} />
                  </td>
                </tr>
                <tr>
                  <th>Administrator</th>
                  <td></td>
                </tr>
                <tr>
                  <th>Limit</th>
                  <td>
                    10GB - <Button variant="contained">Upgrade</Button>
                  </td>
                </tr>
                <tr>
                  <th>Users</th>
                  <td>
                    <Button variant="contained">Invite User</Button>
                  </td>
                </tr>
                <tr>
                  <th>Billing Management</th>
                  <td></td>
                </tr>
                <tr>
                  <th>Delete Project</th>
                  <td>
                    <Button variant="contained">
                      Remove self from project/delete project
                    </Button>
                  </td>
                </tr>
              </table>
            </>
          )}
          {selectedTab === "account" && (
            <>
              <h3>Account</h3>
              <table>
                <tr>
                  <th>Email Address:</th>
                  <td>
                    <Input
                      type="text"
                      disabled
                      value={currentLoggedInUser.user}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Name:</th>
                  <td>
                    <Input type="text" value={""} />
                  </td>
                </tr>
                <tr>
                  <th>Password:</th>
                  <td>
                    <Input type="password" placeholder="Password" />
                    <Input type="password" placeholder="ConfirmPassword" />
                  </td>
                </tr>
              </table>
              <Button>Save Changes</Button>
            </>
          )}
          {selectedTab === "beta" && (
            <>
              <h3>Beta</h3>
              <label>
                <Switch
                  checked={currentLoggedInUser.betaOptIn}
                  onChange={(ev, useBeta) => {
                    dispatch(increaseActiveLoading());
                    fetch(Config.apiEndpoint + "/user/update", {
                      method: "PUT",
                      mode: "cors",
                      headers: { Authorization: "Bearer " + userToken },
                      body: JSON.stringify({
                        beta: useBeta,
                      }),
                    }).then((data) => {
                      dispatch(decreaseActiveLoading());
                    });
                  }}
                />
                Opt-In to Beta functions
              </label>
              <p>
                We'd love you to try out our functions before we have completed
                them! Select this to gain access to our functions earlier than
                most!
                <br />
                Please note - that while our best efforts are made to ensure
                beta functions give you more - from time to time, beta functions
                can have bugs that need ironing out. We accept no liability for
                degraded service if you opt in to beta functions. You should
                consider this if you are experiencing difficulty with this
                enabled.
                <br />
                Additionally, use of a new functionality in beta mode is not an
                indication that this feature will be included or available in
                your current subscription. From time to time, we will assess
                that some functions will require additional charges and reserve
                the right to implement these features for an additional fee.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
